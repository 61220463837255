// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AUTH_TYPE } from 'aws-appsync';

export const environment = {
  production: false,
  apiKeyClient: {
    client: null,
    disableOffline: true,
    url: 'https://f7ieq45yw5afbjvbgbpate6wim.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: 'da2-y5ra2uzyobdqjkyrjop74sw6hm',
    },
    complexObjectsCredentials: null,
  },
  cognitoClient: {
    client: null,
    disableOffline: true,
    url: 'https://7iiixq7genezriniueifasw3hm.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    },
    keyPrefix: 'private',
    complexObjectsCredentials: null,
  },
  // Replaced during the build
  buildNumber: '644',
  RECAPTCHA_V3_SITE_KEY_VALUE: '6LdvAzIfAAAAAIZEqt8W2dYhtdgMc8H7_xTeQ5Qx'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
